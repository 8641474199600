<template>
  <!--begin::Wrapper-->
  <div class="w-lg-1000px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <!--begin::Heading-->
    <div class="text-center mb-10">
      <!--begin::Title-->
      <h1 class="text-dark mb-3">{{ $t("privacy") }}</h1>
      <!--end::Title-->
    </div>
    <!--end::Heading-->

    <!-- Türkçe Bölüm -->
    <div class="mb-5">
      <!--<h5>Türkçe</h5>-->
      <h3>1. Giriş</h3>
      <p>
        Bu Gizlilik Politikası, Maldavar ("biz", "bizim" veya "uygulama")
        tarafından sunulan hizmetlerin kullanımını düzenler. Kullanıcılarımızın
        gizliliği bizim için önemlidir ve bu politika, topladığımız bilgileri,
        bu bilgileri nasıl kullandığımızı ve nasıl koruduğumuzu açıklar.
      </p>

      <h3>2. Topladığımız Bilgiler</h3>
      <h4>2.1. Kamera Erişimi</h4>
      <p>
        Maldavar, belirli özellikler için cihazınızın kamerasına erişim
        gerektirebilir. Bu erişim, örneğin fotoğraf çekmek veya video kaydetmek
        gibi işlemler için kullanılabilir. Kamera erişimi yalnızca sizin
        izninizle gerçekleştirilecektir ve bu erişim sırasında elde edilen
        görüntüler veya videolar, sizin onayınız olmadan üçüncü taraflarla
        paylaşılmayacaktır.
      </p>

      <h4>2.2. SMS Okuma Erişimi</h4>
      <p>
        Maldavar, belirli özellikler için cihazınızdaki SMS mesajlarına erişim
        gerektirebilir. Bu erişim, örneğin kimlik doğrulama kodlarını otomatik
        olarak algılamak veya benzeri işlemler için kullanılabilir. SMS erişimi
        yalnızca sizin izninizle gerçekleştirilecektir ve bu erişim sırasında
        elde edilen veriler, sizin onayınız olmadan üçüncü taraflarla
        paylaşılmayacaktır.
      </p>

      <h3>3. Bilgilerin Kullanımı</h3>
      <p>
        Topladığımız bilgiler, Maldavar'ın işlevselliğini artırmak, kullanıcı
        deneyimini iyileştirmek ve size daha iyi hizmet sunmak için
        kullanılacaktır. Bu bilgiler, aşağıdaki amaçlarla kullanılabilir:
      </p>
      <ul>
        <li>Hizmetlerimizi sunmak ve yönetmek.</li>
        <li>Kullanıcı desteği sağlamak.</li>
        <li>Güvenlik ve dolandırıcılığı önleme amaçlarıyla.</li>
        <li>Yasal yükümlülüklere uymak.</li>
      </ul>

      <h3>4. Bilgilerin Korunması</h3>
      <p>
        Kullanıcı bilgilerinin güvenliğini sağlamak için endüstri standartlarına
        uygun teknik ve idari önlemler alıyoruz. Ancak, internet üzerinden veri
        iletiminin tamamen güvenli olduğunu garanti edemeyiz ve bu nedenle bize
        iletilen bilgilerin güvenliğini garanti edemeyiz.
      </p>

      <h3>5. Üçüncü Taraflarla Paylaşım</h3>
      <p>
        Kullanıcı bilgileriniz, yasal gereklilikler dışında üçüncü taraflarla
        paylaşılmayacaktır. Yasal yükümlülükler, mahkeme emirleri veya yasal
        süreçlere yanıt olarak bilgilerinizin paylaşılması gerekebilir.
      </p>

      <h3>6. Kullanıcı Hakları</h3>
      <p>
        Kullanıcılar, kişisel verilerine erişme, bunları düzeltme, silme veya
        işlenmesine itiraz etme hakkına sahiptir. Bu haklarınızı kullanmak için
        bizimle iletişime geçebilirsiniz.
      </p>

      <h3>7. Gizlilik Politikasında Değişiklikler</h3>
      <p>
        Bu Gizlilik Politikası zaman zaman güncellenebilir. Politika
        değişiklikleri, bu sayfada yayınlanarak kullanıcılarımıza
        bildirilecektir. Kullanıcıların, gizlilik politikamızdaki değişiklikleri
        düzenli olarak kontrol etmeleri önerilir.
      </p>

      <h3>8. Sözleşmeden Doğan Haklarınız</h3>

      <p>
        Bu şartları kabul etmiyorsanız uygulamayı silebilirsiniz. Saklanan
        verilerinizin raporunu destek@ogzatech.com adresinden talep
        edebilirsiniz. Bu durumda sistemde kayıtlı olan email adresiniz ile
        istekte bulunmanız gerekmektedir. Ek güvenlik adımları istenebilir.
        Saklanan verilerinizin silinmesini destek@ogzatech.com adresinden
        isteyebilirsiniz. Bu durumda sistemde kayıtlı olan email adresiniz ile
        istekte bulunmanız gerekmektedir. Ek güvenlik adımları istenebilir.
      </p>

      <h3>9. İletişim</h3>
      <p>
        Gizlilik politikamız hakkında sorularınız veya endişeleriniz varsa,
        lütfen bizimle iletişime geçin.
      </p>
    </div>

    <hr />
    <br />
    <!-- <h5>English</h5>-->
    <!-- English Section -->
    <div>
      <h3>1. Introduction</h3>
      <p>
        This Privacy Policy governs the use of services provided by Maldavar
        ("we," "our," or "the application"). The privacy of our users is
        important to us, and this policy explains what information we collect,
        how we use it, and how we protect it.
      </p>

      <h3>2. Information We Collect</h3>
      <h4>2.1. Camera Access</h4>
      <p>
        Maldavar may require access to your device's camera for certain
        features. This access may be used for actions such as taking photos or
        recording videos. Camera access will only be granted with your
        permission, and any images or videos obtained through this access will
        not be shared with third parties without your consent.
      </p>

      <h4>2.2. SMS Reading Access</h4>
      <p>
        Maldavar may require access to your device's SMS messages for certain
        features. This access may be used for actions such as automatically
        detecting authentication codes or similar purposes. SMS access will only
        be granted with your permission, and any data obtained through this
        access will not be shared with third parties without your consent.
      </p>

      <h3>3. Use of Information</h3>
      <p>
        The information we collect is used to enhance the functionality of
        Maldavar, improve user experience, and provide you with better services.
        This information may be used for the following purposes:
      </p>
      <ul>
        <li>To provide and manage our services.</li>
        <li>To offer user support.</li>
        <li>For security and fraud prevention purposes.</li>
        <li>To comply with legal obligations.</li>
      </ul>

      <h3>4. Protection of Information</h3>
      <p>
        We take industry-standard technical and administrative measures to
        ensure the security of user information. However, we cannot guarantee
        the absolute security of data transmitted over the internet, and
        therefore cannot ensure the security of any information transmitted to
        us.
      </p>

      <h3>5. Sharing of Information with Third Parties</h3>
      <p>
        User information will not be shared with third parties except as
        required by law. Legal requirements may include responding to court
        orders or legal processes.
      </p>

      <h3>6. User Rights</h3>
      <p>
        Users have the right to access, correct, delete, or object to the
        processing of their personal data. To exercise these rights, you can
        contact us.
      </p>

      <h3>7. Changes to the Privacy Policy</h3>
      <p>
        This Privacy Policy may be updated from time to time. Changes to the
        policy will be posted on this page, and users are encouraged to
        regularly review our privacy policy for any updates.
      </p>

      <h3>8. Contractual Rights</h3>
      <p>
        If you do not accept these terms, you can remove the application. You
        can request the report of your stored data from destek@ogzatech.com. In
        this case, you must make a request with your e-mail address registered
        in the system. Additional security steps may be required. You can
        request the removal of your stored data from destek@ogzatech.com.. In
        this case, you must make a request with your e-mail address registered
        in the system. Additional security steps may be required.
      </p>

      <h3>9. Contact</h3>
      <p>
        If you have any questions or concerns about our privacy policy, please
        contact us at.
      </p>
    </div>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/presentation/store/enums/StoreEnums";

import { useStore } from "vuex";
import * as Yup from "yup";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { LoginModel } from "@/domain/authentication/loginUser/model/LoginModel";
import { useI18n } from "vue-i18n";
import { EXCEPTION_TYPE } from "@/core/Constant";
import { CUSTOM_ERROR_MESSAGES, STORE, SWAL_MESSAGES } from "@/domain/Constant";

export default defineComponent({
  name: "privacy",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    // const router = useRouter();
    const swalNotification = new SwalNotification();
    const submitButton = ref<HTMLElement | null>(null);

    const passwordFieldType = ref("password");

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .email(t(CUSTOM_ERROR_MESSAGES.email_invalid))
        .required(t(CUSTOM_ERROR_MESSAGES.email_required))
        .label("Email"),
      password: Yup.string()
        .min(
          EXCEPTION_TYPE.MIN_PASSWORD_LENGTH,
          t(CUSTOM_ERROR_MESSAGES.password_min)
        )
        .required(t(CUSTOM_ERROR_MESSAGES.password_required))
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      await store.dispatch(STORE.CONTROLLERS);

      const userController = store.state.ControllersModule.userController;
      if (!userController) {
        throw new Error("userController is not available in Vuex store.");
      }

      try {
        const response = await userController.login({
          email: values.email,
          password: values.password,
        } as LoginModel);

        if (response.isSuccess) {
          localStorage.setItem("email", values.email);
          swalNotification.success(
            t(SWAL_MESSAGES.LOGIN_SUCCESS_MESSAGE),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        } else {
          swalNotification.error(
            response.error.cause.cause +
              t(SWAL_MESSAGES.ERROR_CODE) +
              response.error.code +
              response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          /*
            swalNotification.error(
              response.error.cause.cause + response.error + response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
            );
            */
        }
      } catch (e) {
        console.log(e);
        swalNotification.error(
          "vue error",
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const togglePasswordVisibility = () => {
      passwordFieldType.value =
        passwordFieldType.value === "password" ? "text" : "password";
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      togglePasswordVisibility,
      passwordFieldType,
    };
  },
});
</script>
